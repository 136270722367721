
import React, {useState, useEffect, useContext} from "react";
import StyleContext from "../../contexts/StyleContext";

import './Facts.scss'
import bgImage from '../../assets/images/cover3.png';
import {Fade} from "react-reveal";

import img12 from '../../assets/images/cover.png';

const Facts = () => {
  const {Langsar} = useContext(StyleContext);//Langsar.intro_content
  const {isDark} = useContext(StyleContext);
  return (
    <div
    className='facts-container '
    style={{ backgroundImage: `url(${bgImage})` }}
  >
<Fade bottom duration={1000} distance="20px">

        <div className=' intro container text-center text-white p-4  facts-text2 position-relative'>
              <div className='facts-text3'><h1 className=' m-1  facts-text3' >{Langsar.Fact1}</h1></div>
              <div className='facts-text3'> <img src={img12} className='card-img-top-logo2 rounded-4' alt='Blog 2' /></div>
              <div className='facts-text3'> </div>
    
              
        
              </div>
          
        
      </Fade></div>
  );
};

export default Facts;
