import "./Contactme2.scss";
import React, { useState } from 'react';

const Contactme2 = () => {
  const [language, setLanguage] = useState('DE'); // Default language set to German (DE)

  const toggleLanguage = () => {
    setLanguage(language === 'DE' ? 'EN' : 'DE');
  };

  return (
    <div className="impressumContainer">
      <button className="languageToggle" onClick={toggleLanguage}>
        {language === 'DE' ? 'Switch to English' : 'Wechseln zu Deutsch'}
      </button>

      {language === 'DE' && (
        <div>
          <h1>Datenschutz</h1>
          <h2>Allgemeines zum Datenschutz:</h2>
          <p>Wir verarbeiten Ihre Daten ausschließlich gemäß den gesetzlichen Bestimmungen der DSGVO, des BDSG und des TTDSG. Der im Impressum genannte Betreiber dieser Website ist der Verantwortliche im Sinne des Art. 4 Z 7 DSGVO. Der Schutz Ihrer persönlichen Daten liegt uns sehr am Herzen. Da wir äußerst sparsam mit Daten umgehen und standardmäßig keine Daten verarbeiten, deren Verarbeitung eine Einwilligung erfordert, verfügt diese Website weder über einen Cookie-Consent-Banner noch über eine Cookie-Bar, es sei denn, wir verwenden auf ausdrücklichen Kundenwunsch einen sogenannten iFrame. In diesem Fall befindet sich ein eigener Cookie-Consent-Banner direkt über dem betreffenden iFrame, und etwaige Cookies werden erst nach ausdrücklicher Zustimmung gesetzt.</p>
          <h2>Zweck der Datenverarbeitung:</h2>
          <p>Die Daten werden zum Betrieb einer informellen und repräsentativen Website verarbeitet und gespeichert.</p>
          <h2>Dauer der Datenspeicherung:</h2>
          <p>Die Daten werden nach Wegfall des Zwecks oder auf Verlangen des Betroffenen inaktiv gesetzt und gesperrt oder nachhaltig gelöscht, sofern dem keine gesetzlichen Aufbewahrungsfristen entgegenstehen.</p>
          <h2>Rechtsgrundlage der Datenverarbeitung:</h2>
          <p>Die Daten werden gemäß Art. 6 Abs. 1 lit. f DSGVO im Rahmen unseres berechtigten Interesses veröffentlicht. Unser Ziel ist es, eine umfassende Darstellung unseres Unternehmens zu bieten und den Nutzern den bestmöglichen Service zu bieten.</p>
          <h2>Kategorien von Daten:</h2>
          <p>Wir verarbeiten personenbezogene Daten in Form von Website-Inhalten, Bildern, gegebenenfalls Videos, Logos, Freitexten sowie Daten der jeweiligen Besucher im Rahmen der Nutzung des Kontaktformulars.</p>
          <h2>Kategorien von Empfängern:</h2>
          <p>Die Daten werden dem jeweiligen Website-Nutzer zur Verfügung gestellt, einschließlich Verbrauchern und Unternehmen. Daten, die über das Kontaktformular bereitgestellt werden, werden dem Verantwortlichen zur Bearbeitung der Anfrage übermittelt.</p>
          <h2>Datenquelle:</h2>
          <p>Die Daten stammen vom Verantwortlichen gemäß Impressum und gegebenenfalls von den jeweiligen Nutzern.</p>
          <h2>Haftung der Nutzer:</h2>
          <p>Alle Nutzer sind verpflichtet, die geltenden Gesetze, insbesondere das Datenschutzgesetz, zu beachten und den Betreiber dieser Website schad- und klaglos zu halten. Die Verwendung der im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von unaufgeforderten Werbe- und Informationsmaterialien wird hiermit ausdrücklich untersagt. Der Betreiber der Website behält sich rechtliche Schritte im Falle unverlangter Zusendung von Werbeinformationen, wie z.B. Spam-Mails, ausdrücklich vor.</p>
          <h2>Soziale Netzwerke:</h2>
          <p>Diese Website kann Links zu den Portalen von Facebook und/oder Instagram enthalten. Die entsprechenden Links sind jeweils durch Icons wie „f“, „i“, „facebook“, „Instagram“ etc. gekennzeichnet. Die jeweiligen sozialen Netzwerke verfügen über eigene Datenschutzinformationen.</p>
          <h2>Videos:</h2>
          <p>Wir bieten Ihnen die Möglichkeit, Filmreportagen über YouTube-Videos direkt über eine iFrame-Einbettung anzusehen. Durch Anklicken einer Filmreportage erteilen Sie gemäß Art. 6 Abs. 1 lit. a DSGVO Ihre Zustimmung. Durch diesen Vorgang kann der Diensteanbieter Google Ireland Limited personenbezogene Daten von Ihnen erheben. Eine Verarbeitung oder Speicherung dieser Daten durch uns findet nicht statt. Ihre Zustimmung behält bis zum Verlassen der Website ihre Gültigkeit und wird nicht gespeichert.</p>
          <h2>Google Fonts:</h2>
          <p>Auf dieser Seite sind externe Schriften, sogenannte „Google Fonts“, implementiert. Die Einbindung erfolgt durch einen Serveraufruf, der von unserem technischen Dienstleister durchgeführt wird.</p>
          <h2>Kontaktformular:</h2>
          <p>Wenn Sie via Kontaktformular auf unserer Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
        </div>
      )}

      {language === 'EN' && (
        <div>
          <h1>Privacy Policy</h1>
          <h2>General Information on Data Protection:</h2>
          <p>We process your data exclusively in accordance with the legal requirements of the GDPR, the BDSG, and the TTDSG. The operator of this website mentioned in the imprint is the responsible party within the meaning of Art. 4 No. 7 GDPR. The protection of your personal data is very important to us. Since we are extremely economical with data and do not process any data that requires consent by default, this website does not have a cookie consent banner or a cookie bar, unless we use an so-called iFrame at the express request of a customer. In this case, a separate cookie consent banner is located directly above the relevant iFrame, and any cookies are only set after express consent.</p>
          <h2>Purpose of Data Processing:</h2>
          <p>The data is processed and stored for the operation of an informational and representative website.</p>
          <h2>Duration of Data Storage:</h2>
          <p>The data is deactivated and locked or permanently deleted after the purpose has ceased to exist or at the request of the data subject, provided that this does not conflict with any statutory retention periods.</p>
          <h2>Legal Basis for Data Processing:</h2>
          <p>The data is published in accordance with Art. 6 Para. 1 lit. f GDPR as part of our legitimate interest. Our goal is to provide a comprehensive presentation of our company and to offer users the best possible service.</p>
          <h2>Categories of Data:</h2>
          <p>We process personal data in the form of website content, images, videos if applicable, logos, free texts, and data of the respective visitors in the context of using the contact form.</p>
          <h2>Categories of Recipients:</h2>
          <p>The data is made available to the respective website user, including consumers and companies. Data provided via the contact form is transmitted to the responsible party for processing the request.</p>
          <h2>Source of Data:</h2>
          <p>The data comes from the responsible party according to the imprint and possibly from the respective users.</p>
          <h2>User Liability:</h2>
          <p>All users are obliged to observe the applicable laws, especially the data protection law, and to indemnify and hold harmless the operator of this website. The use of contact data published within the framework of the imprint obligation by third parties for sending unsolicited advertising and information materials is hereby expressly prohibited. The operator of the website expressly reserves the right to take legal action in the event of the unsolicited sending of advertising information, such as spam mails.</p>
          <h2>Social Networks:</h2>
          <p>This website may contain links to the portals of Facebook and/or Instagram. The respective links are marked by icons such as "f", "i", "facebook", "Instagram", etc. The respective social networks have their own privacy information.</p>
          <h2>Videos:</h2>
          <p>We offer you the opportunity to watch film reports via YouTube videos directly through an iFrame embedding. By clicking on a film report, you give your consent in accordance with Art. 6 Para. 1 lit. a GDPR. Through this process, the service provider Google Ireland Limited may collect personal data from you. We do not process or store this data. Your consent remains valid until you leave the website and is not stored.</p>
          <h2>Google Fonts:</h2>
          <p>External fonts, known as "Google Fonts", are implemented on this page. The integration takes place through a server call, which is carried out by our technical service provider.</p>
          <h2>Contact Form:</h2>
          <p>If you contact us via the contact form on our website or by email, your provided data will be stored for six months for the purpose of processing the request and in case of follow-up questions. We will not share this data without your consent.</p>
        </div>
      )}
    </div>
  );
};

export default Contactme2;