import React from "react";
import "./App.scss";
import Main from "./containers/Main";
import 'bootstrap/dist/css/bootstrap.css';
import Contactme from "./containers/CardPhotos/Contactme";
function App() {
  return (
    <div>
<Main />
    </div>
  );
}

export default App;
