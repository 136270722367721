import "./Contactme2.scss";
import React, { useState } from 'react';

const Contactme2 = () => {
  const [language, setLanguage] = useState('DE'); // Default language set to German (DE)

  const toggleLanguage = () => {
    setLanguage(language === 'DE' ? 'EN' : 'DE');
  };

  return (
    <div className="impressumContainer">
      <button className="languageToggle" onClick={toggleLanguage}>
        {language === 'DE' ? 'Switch to English' : 'Wechseln zu Deutsch'}
      </button>

      {language === 'DE' && (
        <div>
          <h1>Impressum</h1>
          <h2>Handyfix Center</h2>
          <p><strong>Inhaber:</strong> Abdul Rahman Faham<br />
          Elisabethstraße 3, 85051 Ingolstadt<br />
          Rechtsform: Einzelunternehmen<br />
          Geschäftsführung: Abdul Rahman Faham<br />
          Gründungsdatum: 01.05.2024
          </p>
          <div>
            <strong>Kontakt:</strong>
            <p><strong>Telefon:</strong> <a href="tel:+4915781318784">+49 (0) 157 813 187 84</a></p>
            <p><strong>E-Mail:</strong> <a href="mailto:info@hfcenter.de">info@hfcenter.de</a></p>
          </div>
          <p>
          Umsatzsteuer-Identifikationsnummer: DE367761995 gemäß § 27 a Umsatzsteuergesetz
          </p>
          <h2>Allgemeine Nutzungsbedingungen:</h2>
          <p>
            <strong>1 <strong>Haftungsausschluss:</strong></strong> Die Informationen auf dieser Website sind sorgfältig geprüft und zusammengestellt, jedoch kann keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität übernommen werden...
          </p>
          <p>
            <strong>2 <strong>Copyright:</strong></strong> Der Inhalt dieser Website ist urheberrechtlich geschützt. Jede Nutzung außerhalb der Grenzen des Urheberrechts ist ohne Zustimmung des Betreibers untersagt...
          </p>
          <p>
            <strong>3 <strong>Haftung für Links:</strong></strong> Diese Website kann Links zu externen Seiten enthalten, für deren Inhalte wir keine Verantwortung übernehmen...
          </p>
        </div>
      )}

      {language === 'EN' && (
        <div>
          <h1>Legal Notice</h1>
          <h2>Handyfix Center</h2>
          <p><strong>Owner:</strong> Abdul Rahman Faham<br />
          Elisabethstraße 3, 85051 Ingolstadt<br />
          Legal Form: Sole Proprietorship<br />
          Managing Director: Abdul Rahman Faham<br />
          Date of Establishment: 05/01/2024
          </p>
          <div>
            <strong>Contact:</strong>
            <p><strong>Phone:</strong> <a href="tel:+4915781318784">+49 (0) 157 813 187 84</a></p>
            <p><strong>Email:</strong> <a href="mailto:info@hfcenter.de">info@hfcenter.de</a></p>
          </div>
          <p>
          VAT identification number: DE367761995 according to Section 27 a of the Sales Tax Law
          </p>
          <h2>General Terms of Use:</h2>
          <p>
            <strong>1 <strong>Disclaimer:</strong></strong> The information on this website has been carefully checked and compiled, however, no warranty can be assumed for the correctness, completeness, and up-to-dateness...
          </p>
          <p>
            <strong>2 <strong>Copyright:</strong></strong> The content of this website is protected by copyright. Any use outside the limits of copyright law without the consent of the operator is prohibited...
          </p>
          <p>
            <strong>3 <strong>Liability for Links:</strong></strong> This website may contain links to external sites, for which we assume no responsibility...
          </p>
        </div>
      )}
    </div>
  );
};

export default Contactme2;