import React, { useContext } from "react";
import StyleContext from "../../contexts/StyleContext";
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./img_scroll.scss";
import './Facts.scss';
import { Carousel } from "react-bootstrap";
import blogImage1 from '../../assets/images/new-slide/1.jpg';
import blogImage2 from '../../assets/images/new-slide/2.jpg';
import blogImage3 from '../../assets/images/new-slide/3.jpg';
import blogImage4 from '../../assets/images/new-slide/4.jpg';
import blogImage5 from '../../assets/images/new-slide/5.jpg';

const MyComponent = () => {
    const { Langsar } = useContext(StyleContext); // Assuming Langsar is an object containing your texts

    const images = [blogImage1, blogImage2, blogImage3, blogImage4, blogImage5];
    
    const slidetextger = {
        slide1: { p1: "Willkommen beim", p2: "HandyFix Center.", p3: "" },
        slide2: { p1: "Ihr Partner für mobile", p2: "Reparaturen und Dienstleistungen.", p3: "" },
        slide3: { p1: "Vertrauen Sie auf unsere", p2: "Expertise und Erfahrung.", p3: "" },
        slide4: { p1: "Wir verfügen über ", p2: "die neuesten Technologien, ", p3: "um Ihnen den besten Service anzubieten." },
        slide5: { p1: "Schreiben Sie uns eine Anfrage oder ", p2: "Rufen Sie uns jetzt an.", p3: "" },
    };

    const slidetexteng = {
        slide1: { p1: "Welcome to Handy", p2: "Fix Center.", p3: "" },
        slide2: { p1: "Your partner for", p2: "mobile Repairs and services.", p3: "" },
        slide3: { p1: "Trust in ours", p2: "Expertise and experience.", p3: "" },
        slide4: { p1: "We have the latest technologies, ", p2: "to offer you the best service.", p3: "" },
        slide5: { p1: "Write to us an inquiry ", p2: "or call us now.", p3: "" },
    };

    const slideTexts1 = Langsar.lang === "german" ? slidetextger : slidetexteng;

    const slides = images.map((img, index) => {
        
        const slideTexts = slideTexts1[`slide${index + 1}`]; // Accessing slide texts using dynamic keys
        return (
            <Carousel.Item key={index}  interval={8900}>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img src={img} alt={`Slide ${index + 1}`} className={'img_scroll'} />
                    <div className="custom-caption1" style={{
                        position: 'absolute',
                        top: '50%',
                        left: '35%', // Adjusting left alignment
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                    }}>
                        <div className="text-center css-typing d-block ">
                            <p className="fs-1 fw-bolder text-black hero-title-lh mb-0 ">{slideTexts.p1}</p>
                            <p className="fs-1 fw-bolder text-black mt-1 mb-1 mt-md-3 mb-md-3 hero-title-lh">{slideTexts.p2}</p>
                            <p className="fs-1 fw-bolder text-black fs-5 mb-1 mb-md-1 mb-md-auto mb-lg-auto mt-0 mt-md-3 mt-lg-3">{slideTexts.p3}</p>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        );
    });
    return (
        <div>
            <div className='img_scroll_div my-3'>
                <Carousel fade>
                    {slides}
                </Carousel>
            </div>
        </div>
    );
};

export default MyComponent;