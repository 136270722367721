import emoji from "react-easy-emoji";
import {AiOutlineLaptop} from 'react-icons/ai'

//import {blogSection_german,blogSection_eng} from "../portfolio";


const blogSection_german = {
  title: "Warum Sie uns wählen sollten:",
  subtitle:
    "Was uns von anderen unterscheidet:",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      title: "Professionelle Expertise: ",
      description:
        " Unsere vor-Ort-Techniker sind darauf spezialisiert, Ihre technischen Probleme zu lösen.",
   icon:<AiOutlineLaptop className="m-md-3 fa-3x"/>
      },
    {
      title: "Datensicherheit:",
      description:
        "Wir garantieren den Schutz Ihrer Daten während des gesamten Reparatur- und Rettungsprozesses."
    },
    {
      title: "Schneller Service: ",
      description:
        "Wir verstehen, wie wichtig Ihre Geräte für Sie sind. Unsere Techniker arbeiten effizient, damit Sie schnell wieder einsatzbereit sind."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};
const blogSection_eng = {
  title: "Why you should choose us:",
  subtitle:
    "What makes us different from others:",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      title: "Professional expertise:", 
      description:
        "Our on-site technicians specialize in solving your technical problems."
    },
    {
      title: "Data security:",
      description:
        "We guarantee the protection of your data throughout the entire repair and rescue process."
    },
    {


      
      title: "Fast Service:",
      description:
        "We understand how important your devices are to you. Our technicians work efficiently to get you back up and running quickly."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};



export const germany={


//new
  Fact1:"Seit über 12 Jahren haben wir Erfahrung im Umgang mit Smart-Geräten",
  
  imagestitles: {
    phone: "Handy",
    tablet: "Tablet",
    laptop: "Laptop",
    watch: "Uhr"
  },
  icons: {
    phone: [
      "Batterieproblem",
      "Bildschirmrisse",
      "Softwarefehler",
      "Kameradefekt",
      "Lautsprecherproblem",
      "Ladebuchse"
    ],
    tablet: [
      "Bildschirmersatz",
      "Wasserschaden",
      "Knopfersatz",
      "Batterieersatz",
      "Audio-Probleme",
      "Konnektivitätsprobleme"
    ],
    laptop: [
      "Tastaturaustausch",
      "Überhitzung",
      "Festplattenausfall",
      "RAM-Upgrade",
      "Virenbeseitigung",
      "Motherboard-Reparatur"
    ],
    watch: [
      "Armbandwechsel",
      "Bildschirmprobleme",
      "Batterieentleerung",
      "Reparatur von Wasserschäden",
      "Sensorprobleme",
      "Software-Aktualisierungsprobleme"
    ]
  },

//newwwwwwwwwwwww

  slide1: {p1: "Willkommen beim", p2: "HandyFix Center.", p3: ""},
  slide2: {p1: "Ihr Partner für mobile", p2: "Reparaturen und Dienstleistungen.", p3: ""},
  slide3: {p1: "Vertrauen Sie auf unsere", p2: "Expertise und Erfahrung.", p3: ""},
  slide4: {p1: "Wir verfügen über ", p2: "die neuesten Technologien, ", p3: "um Ihnen den besten Service anzubieten."},
  slide5: {p1: "Schreiben Sie uns eine Anfrage oder ", p2: "rufen Sie uns jetzt an.", p3: ""},




  blogSection:blogSection_german,
  lang:"german",

  header_loc:"Elisabethstr.3 85051 Ingolstadt",
  header_num:"(+49) 157 813 18784",


  header1: "Kontaktiere mich",
  header2: "Marken",
  header3: "Warum wir",
  header4: "Datenschutz",
  header5: "Ein Blick",
  header6: "aboutusgerm",
  header7: "Die Preise",
  header8: "CEO",
  header9: "Impressum",




  serv1_sen1:"1",
  serv1_sen2:"2",
  serv1_sen3:"3",
  serv1_sen4:"4",
  serv1_sen5:"5",
  serv1_sen6:"6",
  serv1_sen7:"7",


  serv2_sen1:"11",
  serv2_sen2:"22",
  serv2_sen3:"33",
  serv2_sen4:"44",
  serv2_sen5:"55",
  serv2_sen6:"66",
  serv2_sen7:"77",

  
  serv3_sen1:"111",
  serv3_sen2:"222",
  serv3_sen3:"333",
  serv3_sen4:"444",
  serv3_sen5:"555",
  serv3_sen6:"666",
  serv3_sen7:"777",


  serv4_sen1:"1111",
  serv4_sen2:"2222",
  serv4_sen3:"3333",
  serv4_sen4:"4444",
  serv4_sen5:"5555",
  serv4_sen6:"6666",
  serv4_sen7:"7777",





  discount_header:"Unsere Preise im Überblick:",

  discount1:"Displaytausch",

  discount1_h1:"iPhone:Ab €79.00",
  discount1_h2:"Samsung:Ab €99.00",
  discount1_h3:"Google:Ab €99.00",

  discount2:"Akkutausch:",

  discount2_h1:"iPhone:Ab €50.00",
  discount2_h2:"Samsung:Ab €50.00",
  discount2_h3:"Google:Ab €50.00",

  discount3:"Kleine Reparaturen:",
  discount3_h1:"Ladeanschlussreparatur:Ab€79.00",
  discount3_h2:"Lautsprecherersatz:Ab €79.00",
  discount3_h3:"Kamerareparatur:Ab €79.00",
  
  discount4:"Handy Dienstleistungen:",
  discount4_h1:"Ladebuchse reinigen: €15.00",
  discount4_h2:"Schutzglas aufbringen: €5.00 pro Gerät",  ///"Erste Einrichtung: €25.00 pro Gerät" iwonnt this more

//
discount4_h3:"Software Update: €19.00 ",
discount4_h4:"Datan übertragen (ohne erste Einrichtung): €15.00",
discount4_h5:"Hörmuscheln reinigen: €15.00",
discount4_h6:"Spezielle Beratung: €5.00 je 5 Minuten",

discount5:"Laptop Dienstleistungen:",
discount5_h1:"Komponenten tauschen:  €25.00",
discount5_h2:"Neuinstallation: €55.00",
discount5_h3:"Intensive innere Reinigung: €45.00",   //////"Erste Einrichtung: €25.00 pro Gerät"   iwonnt more

contactintrotitle:"Schick uns eine Nachricht",
contactintro:"Schicken Sie uns eine Nachricht, wenn Sie Fragen zu unseren Dienstleistungen haben oder Reparaturarbeiten benötigen. Wir sind hier, um Ihnen zu helfen:",
Mr:"Herr",
Mrs:"Frau",
//

title_form:"Anrede",
contactName:"Name",
firstName:"Vorname",
contactEmail:"Email-Adresse",
phoneNumber:"Rufnummer",
contactProblem:"Ihr Gerät",//Descunt
subject:"Betreff",
contactMessage:"Ihre Nachricht",//Descunt   //I don't want to see any descunt
contactSubmit:"Senden",//Descunt




  welcome:"empty_unused",

  intro_heading:"", //remove
  intro_content:"Mit über zwölf Jahren Erfahrung im Bereich Hard- und Software bieten wir Reparatur- und Dienstleistungen für mobile Endgeräte mit den neuesten Technologien an. Unser Motto lautet: 'Den Wert Ihrer Smart-Geräte bewahren'. Als Ihre lokalen Technologieexperten helfen wir Ihnen dabei, Ihre defekten Geräte reparieren zu lassen, ohne dabei Daten zu verlieren. Bei uns können Sie sich darauf verlassen. Egal ob Handy, Laptop, Navi oder Tablet, wir stehen Ihnen mit unserer Expertise zur Seite.",
  contactme:"KONTAKTIERE UNS",

  brands_title:"Welche Marken unterstützen wir?",
  brands_subtitle:"Wir unterstützen die meisten Mobiltelefonmarken:",
  serv_title:"Wir bieten verschiedene Dienstleistungen an",  
  serv_sub:"Unsere Services:",
  serv1:"Displayaustausch:",
  serv1_exp:"Unsere Spezialisten bieten Display-Reparaturen ausschließlich mit Original-Ersatzteilen an. Dadurch wird sichergestellt, dass Ihr Display in Qualität und Leistung dem Original entspricht.",
  

  servheading1:"HARDWARE",
  servheading2:"SOFTWARE & IT-SERVICES",
  servheading3:"AN-VERKAUF",


  serv1_1:"Displayaustausch:",
  serv1_2:"Akkus:",

  serv1_3:"Wasserschaden:",//
  serv1_4:"Ladeanschlüssen:",//
  serv1_5:"Ohrmuscheln und Mikrofon:",//


  serv2_1:"Installation, Updates & Fehlerbehebung:",
  serv2_2:"Datenwiederherstellung:",

  serv2_3:"Installation, Updates & Fehlerbehebung:",//
  serv2_4:"Datenwiederherstellung:",//

  serv3_1:"Mobilen Endgeräten:",
  serv3_2:"Simkarten & Internet:",


  serv1_1_exp:"Unsere Spezialisten bieten Display-Reparaturen ausschließlich mit Original-Ersatzteilen an. Dadurch wird sichergestellt, dass Ihr Display in Qualität und Leistung dem Original entspricht.",
  serv1_2_exp:"Wir bieten einen professionellen Akkuaustauschservice an, um sicherzustellen, dass Ihr Gerät eine optimale Batterieleistung bietet.",
  serv1_3_exp:"Unsere Experten sind darauf spezialisiert, Wasserschäden zu diagnostizieren und zu beheben. Wir setzen modernste Technologien ein, um Ihr Gerät zu retten.",
  serv1_4_exp:"Falls Ihr Ladeanschluss Probleme aufweist, kümmern wir uns um die Reparatur, um eine reibungslose Aufladung zu gewährleisten.",
  serv1_5_exp:"Wir bieten professionelle Reinigungsdienste für Ohrmuscheln von AirPods, Kopfhörern und Handys an.",

  serv2_1_exp:"Unsere Experten installieren Programme und Betriebssysteme, führen Softwareaktualisierungen durch und beheben Fehler, damit Ihr Gerät stets auf dem neuesten Stand und reibungslos läuft.",
  serv2_2_exp:"Bei Datenverlust sind wir für Sie da. Unser Ziel ist es, Ihre wertvollen Daten wiederherzustellen und Ihnen Sicherheit zu geben.",
  serv2_3_exp:"Unsere Experten installieren Programme und Betriebssysteme, führen Softwareaktualisierungen durch und beheben Fehler, damit Ihr Gerät stets auf dem neuesten Stand und reibungslos läuft.",
  serv2_4_exp:"Bei Datenverlust sind wir für Sie da. Unser Ziel ist es, Ihre wertvollen Daten wiederherzustellen und Ihnen Sicherheit zu geben.",

  serv3_1_exp:"Wir bieten eine vielfältige Auswahl an Notebooks führender Marken wie HP, Lenovo, Dell, Acer und vielen anderen mehr. Darüber hinaus führen wir Tablets und Smartphones in unserem Sortiment. Für preisbewusste Kunden bieten wir zudem generalüberholte Geräte mit einer Garantie von mindestens 12 Monaten an",
  serv3_2_exp:"Wir bieten eine Auswahl an Daten- und All-Net-Flat-Tarifen von 1&1 an, ergänzt durch passende Endgeräte wie Tablets und Smartphones. Zudem führen wir auch Handyverträge von 1&1 und bieten Internetlösungen für Zuhause an.",




  blogs1: [
    {
      title: "Displayaustausch:",
      description:
      "Unsere Spezialisten bieten Display-Reparaturen ausschließlich mit Original-Ersatzteilen an. Dadurch wird sichergestellt, dass Ihr Display in Qualität und Leistung dem Original entspricht.",
      },
    {
      title: "Akkus:",
      description:
      "Wir bieten einen professionellen Akkuaustauschservice an, um sicherzustellen, dass Ihr Gerät eine optimale Batterieleistung bietet.",
      },
      {
        title: "Wasserschaden:",
        description:
        "Unsere Experten sind darauf spezialisiert, Wasserschäden zu diagnostizieren und zu beheben. Wir setzen modernste Technologien ein, um Ihr Gerät zu retten.",
        },
        {
          title: "Ladeanschlüssen:",
          description:
          "Falls Ihr Ladeanschluss Probleme aufweist, kümmern wir uns um die Reparatur, um eine reibungslose Aufladung zu gewährleisten.",
          },
          {
            title: "Ohrmuscheln und Mikrofon:",
            description:
            "Wir bieten professionelle Reinigungsdienste für Ohrmuscheln von AirPods, Kopfhörern und Handys an.",
            }
    ],

    blogs2: [
      {
        title: "Installation, Updates & Fehlerbehebung:",
        description:
        "Unsere Experten installieren Programme und Betriebssysteme, führen Softwareaktualisierungen durch und beheben Fehler, damit Ihr Gerät stets auf dem neuesten Stand und reibungslos läuft.",
        },
      {
        title: "Datenwiederherstellung:",
        description:
        "Bei Datenverlust sind wir für Sie da. Unser Ziel ist es, Ihre wertvollen Daten wiederherzustellen und Ihnen Sicherheit zu geben.",
        },
        {
          title: "Installation, Updates & Fehlerbehebung:",
          description:
          "Unsere Experten installieren Programme und Betriebssysteme, führen Softwareaktualisierungen durch und beheben Fehler, damit Ihr Gerät stets auf dem neuesten Stand und reibungslos läuft.",
          },
          {
            title: "Datenwiederherstellung:",
            description:
            "Bei Datenverlust sind wir für Sie da. Unser Ziel ist es, Ihre wertvollen Daten wiederherzustellen und Ihnen Sicherheit zu geben.",
            },

      ],
      blogs3: [
        {
          title: "Mobilen Endgeräten:",
          description:
          "Wir bieten eine vielfältige Auswahl an Notebooks führender Marken wie HP, Lenovo, Dell, Acer und vielen anderen mehr. Darüber hinaus führen wir Tablets und Smartphones in unserem Sortiment. Für preisbewusste Kunden bieten wir zudem generalüberholte Geräte mit einer Garantie von mindestens 12 Monaten an",

          },
        {
          title: "Simkarten & Internet:",
          description:
          "Wir bieten eine Auswahl an Daten- und All-Net-Flat-Tarifen von 1&1 an, ergänzt durch passende Endgeräte wie Tablets und Smartphones. Zudem führen wir auch Handyverträge von 1&1 und bieten Internetlösungen für Zuhause an.",
          },
         
  
        ],

      

serv2:"Akkus:",
serv2_exp:"Wir bieten einen professionellen Akkuaustauschservice an, um sicherzustellen, dass Ihr Gerät eine optimale Batterieleistung bietet.",

serv3:"Wasserschaden:",
serv3_exp:"Unsere Experten sind darauf spezialisiert, Wasserschäden zu diagnostizieren und zu beheben. Wir setzen modernste Technologien ein, um Ihr Gerät zu retten.",

serv4:"Ladeanschlüssen:",
serv4_exp:"Falls Ihr Ladeanschluss Probleme aufweist, kümmern wir uns um die Reparatur, um eine reibungslose Aufladung zu gewährleisten.",

serv5:"Softwareaktualisierung und Fehlerbehebung:",
serv5_exp:"Unsere Techniker führen Softwareaktualisierungen durch und beheben Fehler, um sicherzustellen, dass Ihr Gerät mit den neuesten Funktionen und ohne Probleme läuft.",


serv6:"Ohrmuscheln und Mikrofon:",
serv6_exp:"Wir bieten professionelle Reinigungsdienste für Ohrmuscheln von AirPods, Headsets, Kopfhörern und Handys an.",

contactInfo_title:"Rufen Sie uns an ☎️",
contactInfo_subtitle:"Wenn Sie Fragen haben, geben Sie bitte Ihre Telefonnummer an. Wir werden uns dann bei Ihnen melden.", //remove
contactInfo_number:"+49-15781318784",
contactInfo_email_address:"info@hfcenter.de",

sen:"ddd",
sen1:    emoji(
  "⚡ Reparaturen ohne Datenverlust: Unsere Techniker überprüfen Ihr Gerät sorgfältig und setzen auf professionelle Reparaturmethoden, um es wieder in Top-Zustand zu bringen, ohne dabei Ihre wichtigen Daten zu verlieren."),
sen2:    emoji(
  "⚡ Datenrettung von alten Geräten: Haben Sie wichtige Daten auf einem alten Gerät? Unsere Techniker helfen Ihnen, diese zu retten und sicher auf Ihr neues Gerät zu übertragen."),
sen3:    emoji(
  "⚡ Ersteinrichtung von neuen Geräten: Ein neues Gerät erworben? Lassen Sie unsere Profis die Ersteinrichtung durchführen, damit Sie sofort loslegen können, ohne sich mit komplizierten Einstellungen herumschlagen zu müssen."),

}


export const english={
  Fact1:"We have had experience working with smart devices for over 12 years.",

  imagesTitles: {
    phone: "eng Phone",
    tablet: "eng Tablet",
    laptop: "eng Laptop",
    watch: "eng Watch"
  },
  iconsText: {
    image1: {
      icon1: "eng icon 1",
      icon2: "eng icon 2",
      icon3: "eng icon 3",
      icon4: "eng icon 4",
      icon5: "eng icon 5",
      icon6: "eng icon 6"
    },
    image2: {
      icon1: "eng icon 7",
      icon2: "eng icon 8",
      icon3: "eng icon 9",
      icon4: "eng icon 10",
      icon5: "eng icon 11",
      icon6: "eng icon 12"
    },
    image3: {
      icon1: "eng icon 13",
      icon2: "eng icon 14",
      icon3: "eng icon 15",
      icon4: "eng icon 16",
      icon5: "eng icon 17",
      icon6: "eng icon 18"
    },
    image4: {
      icon1: "eng icon 19",
      icon2: "eng icon 20",
      icon3: "eng icon 21",
      icon4: "eng icon 22",
      icon5: "eng icon 23",
      icon6: "eng icon 24"
    }
  },




  slide1: {p1: "Welcome to Handy", p2: "Fix Center.", p3: ""},
  slide2: {p1: "Your partner for", p2: "mobile Repairs and services.", p3: ""},
  slide3: {p1: "Trust in ours", p2: "Expertise and experience.", p3: ""},
  slide4: {p1: "We have the latest technologies, ", p2: "to offer you the best service.", p3: ""},
  slide5: {p1: "Write to us an inquiry ", p2: "or call us now.", p3: ""},






  
  blogSection:blogSection_eng,
  lang:"english",
  header: "empty_unused",

  header_loc:"Elisabethstr.3 85051 Ingolstadt",
  header_num:"(+49) 157 813 18784",


header1: "Contact us",
header2: "A glance",
header3: "Brands",
header4: "why us",
header5: "engDatenschutz",
header6: "aboutusgerm",
header7: "prices",
header8: "CEO",
header9: "Copy Rights",



  serv1_sen1:"1",
  serv1_sen2:"2",
  serv1_sen3:"3",
  serv1_sen4:"4",
  serv1_sen5:"5",
  serv1_sen6:"6",
  serv1_sen7:"7",


  serv2_sen1:"11",
  serv2_sen2:"22",
  serv2_sen3:"33",
  serv2_sen4:"44",
  serv2_sen5:"55",
  serv2_sen6:"66",
  serv2_sen7:"77",

  
  serv3_sen1:"111",
  serv3_sen2:"222",
  serv3_sen3:"333",
  serv3_sen4:"444",
  serv3_sen5:"555",
  serv3_sen6:"666",
  serv3_sen7:"777",


  serv4_sen1:"1111",
  serv4_sen2:"2222",
  serv4_sen3:"3333",
  serv4_sen4:"4444",
  serv4_sen5:"5555",
  serv4_sen6:"6666",
  serv4_sen7:"7777",


  discount_header:"Our prices at a glance:",

  discount1:"Display replacement",

  discount1_h1:"iPhone:From €79.00",
  discount1_h2:"Samsung:From €99.00",
  discount1_h3:"Google:From €99.00",

  discount2:"Battery replacement:",

  discount2_h1:"iPhone:From €50.00",
  discount2_h2:"Samsung:From €50.00",
  discount2_h3:"Google:From €50.00",

  discount3:"Small repairs:",
  discount3_h1:"Charging port repair :From €79.00",
  discount3_h2:"Speaker Replacement :From €79.00",
  discount3_h3:"Camera Repair :From €79.00",
  
  discount4:"Services:",
  discount4_h1:"Clean charging socket: €15.00",
  discount4_h2:"Apply protective glass: €5.00 per device",
  ////
  discount4_h3:"Software Update: €19.00 ",
  discount4_h4:"Transfer data (without initial setup): €15.00",
  discount4_h5:"Cleaning earcups: €15.00",
  discount4_h6:"Special advice: €5.00 per 5 minutes",
  
  discount5:"Laptop Services:",
  discount5_h1:"Exchange components: €25.00",
  discount5_h2:"New installation: €55.00",
  discount5_h3:"Intensive inner cleaning: €45.00",

  contactintrotitle:"Send us a message",
  contactintro:"Send us a message if you have any questions about our services or need repair work. We are here to help you:",
  Mr:"Mr",
  Mrs:"Mrs",


  title_form:"Title",
  contactName:"Name",
  firstName:"First Name",
  contactEmail:"Email",
  phoneNumber:"PhoneNumber",
  contactProblem:"Problem Name",
  subject:"Subject",
  contactMessage:"Message",
  contactSubmit:"Submit",

 


  
  welcome:"empty_unused",


  intro_heading:"",
  intro_content:"A passionate cell phone fix center 🚀 with experience in repairing mobile applications using the latest technologies, Repair instead of throwing away: your local technology expert, your electronic devices deserve a second chance! With us you can have your defective devices repaired without data loss. Whether cell phone, laptop or tablet – we are at your side.",
  contactme:"CONTACT ME german",
  brands_title:"Which brands do we support?",
  brands_subtitle:"We support most mobile phone brands:",
  serv_title:"We offer various services",
  serv_sub:"Our PC and cell phone services:",
  serv1:"Display replacement with original parts:",
  serv1_exp:"Our specialists offer display repairs exclusively using original spare parts. This ensures that your display matches the original in quality and performance.",
  

  servheading1:"HARDWARE",
  servheading2:"SOFTWARE & IT SERVICES",
  servheading3:"On-sale",


  serv1_1:"Display replacement:",
  serv1_2:"Batteries:",

  serv1_3:"Water damage:",//
  serv1_4:"Charging ports:",//
  serv1_5:"Earcups and microphone:",//

  serv2_1:"Installation, Updates & Troubleshooting:",
  serv2_2:"Data recovery:",

  serv2_3:"Installation, Updates & Troubleshooting:",//
  serv2_4:"Data recovery:",//

  serv3_1:"Mobile devices:",
  serv3_2:"Sim cards & Internet:",

  serv1_1_exp:"Our specialists offer display repairs exclusively using original spare parts. This ensures that your display corresponds to the original in quality and performance.",
   serv1_2_exp:"We offer a professional battery replacement service to ensure your device provides optimal battery performance.",
   serv1_3_exp:"Our experts specialize in diagnosing and repairing water damage. We use state-of-the-art technologies to save your device.",
   serv1_4_exp:"If your charging port has problems, we will take care of the repair to ensure smooth charging.",
   serv1_5_exp:"We provide professional cleaning services for AirPods, headphones and cell phones earcups.",

   serv2_1_exp:"Our experts install programs and operating systems, perform software updates, and troubleshoot errors to keep your device up to date and running smoothly.",
   serv2_2_exp:"If you lose data, we are there for you. Our goal is to restore your valuable data and give you peace of mind.",
   serv2_3_exp:"Our experts install programs and operating systems, perform software updates, and troubleshoot errors to keep your device up to date and running smoothly.",
   serv2_4_exp:"If you lose data, we are there for you. Our goal is to restore your valuable data and give you peace of mind.",

   serv3_1_exp: "We offer a diverse selection of notebooks from leading brands such as HP, Lenovo, Dell, Acer and many others. We also have tablets and smartphones in our range. For price-conscious customers, we also offer refurbished devices with a guarantee of at least 12 months",
   serv3_2_exp: "We offer a selection of data and all-net flat tariffs from 1&1, supplemented by suitable devices such as tablets and smartphones. We also have cell phone contracts from 1&1 and offer internet solutions for the home.",














  
  serv2:"batteries Replacement:",
  serv2_exp:"We offer a professional battery replacement service to ensure your device provides optimal battery performance.",
  
  serv3:"Water damage repair:",
  serv3_exp:"Our experts specialize in diagnosing and repairing water damage. We use cutting-edge technologies to save your device.",
  
  serv4:"Charging port repair:",
  serv4_exp:"Our experts specialize in diagnosing and repairing water damage. We use cutting-edge technologies to save your device.",
  
  serv5:"Software update and bug fixes:",
  serv5_exp:"Our technicians perform software updates and troubleshoot to ensure your device is running with the latest features and without any issues.",
  
  serv6:"Data recovery:",
  serv6_exp:"In the event of data loss, we are here to help you. We strive to restore your valuable data.",
 
  contactInfo_title:"Contact us",
  contactInfo_subtitle:"IF YOU HAVE ANY INQUIRIES PROVIDE YOUR PHONE NUMBER, AND WE WILL GET BACK TO YOU.",//remove
  contactInfo_number:"+49-15781318784",
  contactInfo_email_address:"info@hfcenter.com",


 
  sen:"Abdulrahman Faham",
  sen1: 
    "Repairs without data loss: Our technicians carefully check your device and use professional repair methods to get it back to top condition without losing your important data.",
sen2: emoji(
  "⚡Data recovery from old devices: Do you have important data on an old device? Our technicians will help you rescue them and transfer them safely to your new device."),
sen3: emoji(
  "⚡Initial setup of new devices: Purchased a new device? Let our pros handle the initial setup so you can get started right away without having to deal with complicated settings."),
}  


export const Langsar = (Germ=true) => {
  if(Germ==true){return germany}
  else{return english}
};


