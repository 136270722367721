import React, {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";
import {Fade} from "react-reveal";
import { Tabs, Tab } from "react-bootstrap";
import "./Techs.scss";
// reactstrap components
//<p><a href="#" class="btn-custom">Read more</a></p>

//<AiOutlineLaptop className="mx-1 fa-1x"/>
import {
  Container,
} from "reactstrap";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import {ImTablet} from 'react-icons/im'
import {IoPhonePortraitOutline} from 'react-icons/io5'
import {AiOutlineLaptop} from 'react-icons/ai'
import {BsSmartwatch} from 'react-icons/bs'
import bgImage from '../../assets/images/black/black4.jpg';

import "../blogs/Blog.scss";
import BlogCard from "../../components/blogCard/BlogCard";


export default function Services() {
  const {Langsar} = useContext(StyleContext);//Langsar.intro_content
  const {isDark} = useContext(StyleContext);
  const Demo = () => {
    return <div>Tab 4 using component screen readers</div>;
  };
  return (
    <div
    className='facts-container '
    style={{ backgroundImage: `url(${bgImage})` }}
  >
    <Fade bottom duration={1000} distance="40px">
   <section className="ftco-section" id="services">
    	<div className="container-fluid "
      
      
      >
        <div className="greet-main"
        >
    		<div className=" row justify-content-center pb-5 mb-3">
          <div className="col-md-7 heading-section text-center ftco-animate fadeInUp ftco-animated">
          	<span className="subheading ">{Langsar.serv_title}</span>
            
            <h2>{Langsar.serv_sub}</h2>
          </div>
        </div>
    		<div className="row">
          <div className="col-md-4 services ftco-animate fadeInUp ftco-animated">
          <h3 className="heading ">{Langsar.servheading1}</h3>
            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-car-service"></span>
              </div>
              
              <div className="media-body pl-3">
              
                <h5 className="heading">{Langsar.serv1_1}</h5>
                <p className="blog-card">{Langsar.serv1_1_exp}</p>
              </div>
            </div>
            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-tyre"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv1_2}</h5>
               
                <p className="blog-card">{Langsar.serv1_2_exp}</p>
              </div>
            </div> 


            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-tyre"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv1_3}</h5>
                <p className="blog-card">{Langsar.serv1_3_exp}</p>
              </div>
            </div> 

            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-tyre"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv1_4}</h5>
                <p className="blog-card">{Langsar.serv1_4_exp}</p>
              </div>
            </div> 

            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-tyre"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv1_5}</h5>
                <p className="blog-card">{Langsar.serv1_5_exp}</p>
              </div>
            </div> 
          </div>
          <div className="col-md-4 services ftco-animate fadeInUp ftco-animated">
          <h3 className="heading">{Langsar.servheading2}</h3>
            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-battery"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv2_1}</h5>
                <p className="blog-card">{Langsar.serv2_1_exp}</p>
              </div>
            </div> 
            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-car-engine"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv2_2}</h5>
                <p className="blog-card">{Langsar.serv2_2_exp}</p>
              </div>
            </div>    
            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-car-engine"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv2_3}</h5>
                <p className="blog-card">{Langsar.serv2_3_exp}</p>
              </div>
            </div> 
            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-car-engine"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv2_4}</h5>
                <p className="blog-card">{Langsar.serv2_4_exp}</p>
              </div>
            </div> 


          </div>

          <div className="col-md-4 services ftco-animate fadeInUp ftco-animated">
          <h3 className="heading">{Langsar.servheading3}</h3>


          
          <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-repair"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv3_1}</h5>
                <p className="blog-card"> {Langsar.serv3_1_exp}</p>
              </div>
            </div>  



            
            <div className="d-block d-flex">
              <div className="icon d-flex justify-content-center align-items-center">
            		<span className="flaticon-repair"></span>
              </div>
              <div className="media-body pl-3">
                <h5 className="heading">{Langsar.serv3_2}</h5>
                <p className="blog-card">{Langsar.serv3_2_exp}</p>
              </div>
            </div>       
          </div>
        </div>
        </div>
    	</div>
    </section>
  </Fade>
  </div>
  );
}
//in this stage we have to add a new tech that can adjust