import { useState , useContext} from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import 'react-toastify/dist/ReactToastify.min.css';
import Map from "./Map";
import Header from "../../components/header/Header";
import StyleContext from "../../contexts/StyleContext";
import Facts from "../greeting/Facts";

import "./Contactme.scss";
const ContactForm = () => {
  const {Langsar} = useContext(StyleContext);//Langsar.intro_content
  const {isDark} = useContext(StyleContext);
  const [title, setTitle] = useState('Mr');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const [disabled, setDisabled] = useState(false);


  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  
  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const {title, name,firstname, email,phoneNumber,contactProblem, subject, message } = data;
    try {
      // Disable form while processing submission
      setDisabled(true);

      // Define template params//phoneNumbercontactProblemsubject
      const templateParams = {
        title,
        name,
        firstname,
        email,
        phoneNumber,
        contactProblem,
        subject,
        message,
      };

      // Use emailjs to email contact form data
     // await emailjs.send(
       // "service_bpt9f78",
      //  "template_vwgbzwl",
      //  templateParams,
      //  "swnWUb5P7Re8-P6jC",
      //);
      await emailjs.send(
        "service_hqrm5ba",
        "template_wurd48p",
        templateParams,
        "7sjxe2DFPopa5VI6Y",
      );

      // Reset contact form fields after submission
      reset();
      // Display success toast
      toastifySuccess();
      // Re-enable form submission


      setDisabled(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
    <div className='container-fluid lg_back'id='contactme'>

{/* More components or HTML elements can go here */}
<div className='greet-main bg-transparent'>
<div className="container1 bg-transparent">
    <div className="content">
      <div className="left-side">
        <div className="address details">
          <i className="fas fa-map-marker-alt"></i>
          <div className="topic">Address</div>
          <div className="text-one">Elisabethstraße 3</div>
          <div className="text-two">85051 Ingolstadt</div>
        </div>
        <div className="phone details">
          <i className="fas fa-phone-alt"></i>
          <div className="topic">Phone</div>
          <div className="text-one">+4915781318784</div>
        </div>
        <div className="email details">
          <i className="fas fa-envelope"></i>
          <div className="topic">{Langsar.contactEmail}</div>
          <div className="text-one">info@hfcenter.de</div>
        </div>
      </div>
      <div className="right-side">
        <div className="topic-text">{Langsar.contactintro}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-box">
      <select
      name='title'
      {...register('title', {
        required: {
          value: true,
          message: 'Please select a title',
        },
      })}
      className='select'
      onChange={(e) => setTitle(e.target.value)}
       // Update the title state
    >
      <option value='Mr'>{Langsar.Mr}</option>
      <option value='Mrs'>{Langsar.Mrs}</option>
    </select></div>
        <div className="input-box">
          <h6 className='topic-text h6_1 col-1' >{Langsar.contactName}:</h6>
        <div className='col-11'>
        <input
        
                      type='text'
                      name='name'
                      {...register('name', {
                        required: {
                          value: true,
                          message: 'Please enter your name'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      placeholder={Langsar.contactName}
                    ></input></div>
        </div>
        {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
{}
        <div className="input-box">
        <h6 className='topic-text h6_1 col-1' >{Langsar.firstName}:</h6>
        <input
                      type='text'
                      name='firstname'
                      {...register('firstname', {
                        required: {
                          value: true,
                          message: 'Please enter your firstname'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      placeholder={Langsar.firstName}
                    ></input>
        </div>
        {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
{}
{}
        <div className="input-box">
        <h6 className='topic-text h6_1 col-1' >Email:</h6>
        <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      placeholder='Email address'
                    ></input>
        </div>
        {errors.email && (
                      <span className='errorMessage'>Please enter a valid email address</span>
                    )}
{}
{}
        <div className="input-box">
        <h6 className='topic-text h6_1 col-1' >{Langsar.phoneNumber}:</h6>
        <input
                      type='text'
                      name='phoneNumber'
                      {...register('phoneNumber', {
                        required: {
                          value: true,
                          message: 'Please enter your phoneNumber'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      placeholder={Langsar.phoneNumber}
                    ></input>
        </div>

{}
{}
        <div className="input-box">
        <h6 className='topic-text h6_1 col-1' >{Langsar.contactProblem}:</h6>
        <input
                      type='text'
                      name='contactProblem'
                      {...register('contactProblem', {
                        required: {
                          value: true,
                          message: 'Please enter your Problem'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      placeholder={Langsar.contactProblem}
                    ></input>
        </div>
{}
{}
        <div className="input-box">
        <h6 className='topic-text h6_1 col-1' >{Langsar.subject}:</h6>
        <input
                      type='text'
                      name='subject'
                      {...register('subject', {
                        required: {
                          value: true,
                          message: 'Please enter your subject'
                        },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      placeholder={Langsar.subject}
                    ></input>
        </div>
{}
<div className="input-box ">
<h6 className='topic-text h6_2 col-1 mb-2 text-left'  >{Langsar.contactMessage}:</h6>
</div>
<div className=""> 
        <textarea
                      className="textarea-responsive" 
                      type='text'
                      name='message'
                      {...register('message', {
                        required: {
                          value: true,
                          message: 'Please enter your message'
                        },
                        maxLength: {
                          value: 500,
                          message: 'Please use 500 characters or less'
                        }
                      })}
                      placeholder={Langsar.contactMessage}
                      rows="2"
                    ></textarea>
        </div>


        <div className="d-flex justify-content-center">
      <button type="submit" disabled={disabled} className="btn btn-primary mt-3">{Langsar.contactSubmit}</button>
    </div>
      </form>
    </div>
    </div>
  </div>

  </div>


  {/* More components or HTML elements can go here */}
    </div> <Map /></>
  );
};

export default ContactForm;



