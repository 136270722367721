import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import {Fade} from "react-reveal";
const libraries = ['places'];
const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
};
const center = {
  lat: 48.7431526, // default latitude
  lng: 11.4370958, // default longitude
};

const App = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBlaYa_eb0TB1rZrcvB0803i_UbLccgx7c',
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <Fade bottom duration={1000} distance="40px">
    <section className="ftco-section">
       <div className="container-fluid ">
         <div className="greet-main">
    <div>
      <GoogleMap
      mapContainerStyle={{ width: '100%', height: `50vh`}}
       
        zoom={18}
        center={center}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
    </div>
    	</div>
    </section>
  </Fade>
  );
};

export default App;

